.root {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  width: 100%;
  max-width: 900px;
  height: 100vh;
}

.tabContent {
  height: 100%;
  max-height: 600px;
  border: 1px solid #ccc;
  padding: 1rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.applyButton {
  align-self: flex-end;
  margin-top: 1rem;
}

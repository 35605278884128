.root {
  display: flex;
  flex-direction: column;
}

.error {
  color: #ff0300;
  background-color: white;
  padding: 4px 8px;
  margin-bottom: 8px;
  border: 1px dashed #ffb0af;
}

.textarea {
  flex: 1;
  resize: none;
  border: none;
  outline: none;

  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
